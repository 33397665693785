import { useContext, useEffect, useState } from "react"
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import AppContext from "../../../../AppContext/AppContext";


function TotalOrderValue({totalOrderValue}) {
    // const [totalOrderValue,setTotalOrderValue] = useState("")
    // const {setOrderValue} = useContext(AppContext)
    // const axiosInstance = useAxiosInstance()
    // useEffect(() => {
    //       async function fetchAndSetTotalOrderValue() {  
    //         const { data } = await axiosInstance.get("totalOrderValue");
    //         setTotalOrderValue(data);
    //         setOrderValue(data?.totalSum)
    //       }
    //       fetchAndSetTotalOrderValue();
    //     }, [axiosInstance,setOrderValue]);

    //     console.log("order Value is",totalOrderValue)
  return (
    <div className="card">
        <p>Total Order Value:</p>
        <h3>${totalOrderValue}</h3>
    </div>

  )
}

export default TotalOrderValue