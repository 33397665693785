import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import {
  PRODUCT_ALL_IMAGE,
  Product_API,
} from "../../../../../../Utilities/APIs/APIs";
import Form from "../../../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import NumberInput from "../../../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import FormSubmitButton from "../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import SelectInput from "../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import slugify from "react-slugify";
import ImageInput from "../../../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import MultiImageInput from "../../../../../Partials/Layouts/Forms/FormInputs/MultiImageInput/MultiImageInput";

function UpdateProducts({
  setShowUpdateForm,
  setShowModal,
  triggerFetch,
  targetID,
}) {
  const axiosInstance = useAxiosInstance();
  const [name, setName] = useState("");
  const [colorWay, setColorWay] = useState("");
  const [sku, setSku] = useState("");
  const [description, setDescription] = useState("");
  const [retailPrice, setRetailPrice] = useState(null);
  const [type, setType] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [cardImage, setCardImage] = useState(null);
  const [previewImages, setPreviewImages] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    async function fetchAndSetProduct() {
      const { data } = await axiosInstance.get(Product_API + targetID);
      console.log(data);
      setName(data.name);
      setColorWay(data.colorWay);
      setSku(data.sku);
      setDescription(data.description);
      setRetailPrice(data.retailCost);
      setType(data.type);
      setIsActive(data.isActive);
      setCardImage(data.cardImage);
    }
    fetchAndSetProduct();
  }, [targetID, axiosInstance]);

  useEffect(() => {
    async function fetchAndSetImages() {
      const { data } = await axiosInstance.get(PRODUCT_ALL_IMAGE + targetID);
      setImages(data);
      setPreviewImages(
        data.map((image) => ({
          _id: image._id,
          image: image.image,
          isNew: false,
        }))
      );
    }
    fetchAndSetImages();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("slug", slugify(name));
    formData.append("colorWay", colorWay);
    formData.append("cardImage", cardImage);
    formData.append("sku", sku);
    formData.append("description", description);
    formData.append("retailCost", retailPrice);
    formData.append("type", type);
    formData.append("isActive", isActive);

    images.forEach((image) => {
      formData.append("image", image);
    });

    const response = await axiosInstance.patch(
      Product_API + targetID,
      formData
    );

    if (response.data) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Product Name`}
        value={name}
        placeholder={`Enter Product Name`}
        setState={setName}
      />
      <ImageInput
        state={cardImage}
        label={`Card Image`}
        value={cardImage}
        setState={setCardImage}
        placeholder={`Enter Product Card  image`}
        allowUpdateImage={true}
      />
      <ShortTextInput
        label={`Product Slug`}
        value={slugify(name)}
        placeholder={`Enter Product Slug`}
      />
      <ShortTextInput
        label={`Product Color Way`}
        value={colorWay}
        placeholder={`Enter Product Color  Way`}
        setState={setColorWay}
      />
      <ShortTextInput
        label={`Product SKU`}
        value={sku}
        placeholder={`Enter Product Sku`}
        setState={setSku}
      />
      <ShortTextInput
        label={`Product Description`}
        value={description}
        placeholder={`Enter Product Description`}
        setState={setDescription}
      />

      <NumberInput
        label={`Retail Price`}
        value={retailPrice}
        placeholder={`Enter Retail Price`}
        setState={setRetailPrice}
      />

      <SelectInput value={isActive} setState={setIsActive}>
        <option value={true}>Archive</option>
        <option value={false}>Active</option>
      </SelectInput>

      <SelectInput value={type} setState={setType}>
        <option value={"sneaker"}>Sneaker</option>
        <option value={"apparel"}>Apparel</option>
      </SelectInput>

      <MultiImageInput
        fieldId="1"
        state={images}
        setState={setImages}
        allowUpdateImage
        previewImages={previewImages}
        setPreviewImages={setPreviewImages}
      />

      <FormSubmitButton text="Create Product" />
    </Form>
  );
}

export default UpdateProducts;
