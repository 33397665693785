import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../../../Utilities/Hooks/useAxiosInstance";

export default function useFetchColorway({ type }) {
  const [colorways, setColorways] = useState([]);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchColorways() {
      const { data } = await axiosInstance.get(
        `/color/getAllColorByType/${type}`
      );
      const colors = data.map((color) => {
        return {
          value: color._id,
          label: color.name,
        };
      });
      setColorways(colors);
    }
    fetchColorways();
  }, [type]);
  return colorways;
}
