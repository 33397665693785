import "./Pagination.css";

export default function Pagination({ page, setPage, pages }) {
  const totalPages = Math.ceil(pages / 10);

  return (
    <div className="pagination">
      <button
        className="pagination__button"
        onClick={() => (page !== 1 ? setPage(page - 1) : setPage(totalPages))}
      >
        &laquo; Previous
      </button>
      {Array.from({ length: totalPages }, (_, index) => (
        <button
          key={index + 1}
          className={`pagination__button ${page === index + 1 ? "active" : ""}`}
          onClick={() => setPage(index + 1)}
        >
          {index + 1}
        </button>
      ))}
      <button
        className="pagination__button"
        onClick={() => (totalPages !== page ? setPage(page + 1) : setPage(1))}
      >
        Next &raquo;
      </button>
    </div>
  );
}
