import { RiCloseLine } from "react-icons/ri";
import "./ImageInput.css";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { PRODUCT_ALL_IMAGE } from "../../../../../../Utilities/APIs/APIs";

function MultiImageInput({
  fieldId,
  state,
  setState,
  children,
  customStyle,
  previewImages,
  setPreviewImages,
  create,
}) {
  const axiosInstance = useAxiosInstance();

  const handleUpdateChange = (e) => {
    const files = Array.from(e.target.files);
    setState([...state, ...files]);
    const imagesPreview = files.map((file) => ({
      image: URL.createObjectURL(file),
      isNew: true,
    }));
    setPreviewImages((prevImages) => [...prevImages, ...imagesPreview]);

    e.target.value = "";
  };

  async function handleImageRemove(indexToRemove, e) {
    e.preventDefault();
    setPreviewImages((prevPreviews) =>
      prevPreviews.filter((_, index) => index !== indexToRemove)
    );
    setState((prevState) =>
      prevState.filter((_, index) => index !== indexToRemove)
    );
  }

  async function handleRemovePImage(id, e) {
    e.preventDefault();
    await axiosInstance.delete(`${PRODUCT_ALL_IMAGE}${id}`);
    setPreviewImages((prevPreviews) =>
      prevPreviews.filter((image) => image._id !== id)
    );
  }
  return (
    <>
      <label className="upload_file_wrapper" htmlFor={`fileInput${fieldId}`}>
        <span className="input_field_label">{children}</span>
        <div className="preview_image">
          {previewImages.map((image, index) => (
            <div key={index} style={{ position: "relative" }}>
              <img
                key={index}
                src={
                  image.isNew
                    ? image.image
                    : process.env.REACT_APP_SPACES_URL + image.image
                }
                alt={`${index}`}
                objectFit="contain"
                w="80px"
                h="80px"
              />
              {create ? (
                <button
                  onClick={(e) => handleImageRemove(index, e)}
                  style={{
                    color: "#f2f2f2",
                    position: "absolute",
                    top: "2px",
                    right: "0",
                    background: "#0d0c0c",
                  }}
                >
                  <RiCloseLine />
                </button>
              ) : (
                <button
                  onClick={(e) =>
                    image.isNew
                      ? handleImageRemove(index, e)
                      : handleRemovePImage(image._id, e)
                  }
                  style={{
                    color: "#f2f2f2",
                    position: "absolute",
                    top: "2px",
                    right: "0",
                    background: "#0d0c0c",
                  }}
                >
                  <RiCloseLine />
                </button>
              )}
            </div>
          ))}
        </div>
      </label>
      <input
        id={`fileInput${fieldId}`}
        type="file"
        onChange={handleUpdateChange}
        multiple
        accept="image/*"
      />
    </>
  );
}

export default MultiImageInput;
