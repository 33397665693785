import  {  useState, useEffect } from 'react';

function TotalPayableValue({totalOrderValue,totalPaymentValue}) {
  const [payablePayment, setPayablePayment] = useState(null);

  useEffect(() => {
    if (totalOrderValue !== null && totalPaymentValue !== null) {
      const newPayablePayment = totalOrderValue - totalPaymentValue;
      setPayablePayment(newPayablePayment);
    }
  }, [totalOrderValue,totalPaymentValue]);

  return (

    <div className="card">
    <p>Total Payable Amount:</p>
    <h3>$ {payablePayment !== null ? payablePayment : 'Calculating...'}</h3>
  </div>

  );
}

export default TotalPayableValue;

