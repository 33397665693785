import { useContext, useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import AppContext from "../../../../AppContext/AppContext";


function TotalPaidValue({totalPaymentValue}) {

    // const [totalPaidValue,setTotalPaidValue] = useState("")
    // const {setPaidValue} = useContext(AppContext)
    // const axiosInstance = useAxiosInstance()
    // useEffect(() => {
    //       async function fetchAndSetTotalPaidValue() {  
    //         const { data } = await axiosInstance.get("totalPaymentToSeller");
    //         setTotalPaidValue(data);
    //         setPaidValue(data?.totalAmount)
    //       }
    //       fetchAndSetTotalPaidValue();
    //     }, [axiosInstance,setPaidValue]);
  return (
    <div className="card">
      <p>Total Paid Value: </p>
        <h3>$ {totalPaymentValue}</h3>
    </div>
  )
}

export default TotalPaidValue