import { useState } from "react";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { Product_API } from "../../../../../../Utilities/APIs/APIs";
import Form from "../../../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import slugify from "react-slugify";
import ImageInput from "../../../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import MultiImageInput from "../../../../../Partials/Layouts/Forms/FormInputs/MultiImageInput/MultiImageInput";
import useFetchColorway from "./Hooks/useFetchColorway";
import AsyncSelect from "react-select/async";

function CreateProduct({
  setShowCreateForm,
  setShowModal,
  triggerFetch,
  type,
}) {
  const axiosInstance = useAxiosInstance();

  const [name, setName] = useState("");
  const [colorWay, setColorWay] = useState([]);
  const [sku, setSku] = useState("");
  const [description, setDescription] = useState("");
  const [cardImage, setCardImage] = useState(null);
  const [previewImages, setPreviewImages] = useState([]);
  const [images, setImages] = useState([]);

  const colorways = useFetchColorway({ type });

  async function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData();

    formData.append("name", name);
    formData.append("slug", slugify(name));

    formData.append("cardImage", cardImage);
    formData.append("sku", sku);
    formData.append("description", description);
    formData.append("type", type);
    images.forEach((image) => {
      formData.append("image", image);
    });

    if (colorWay) {
      formData.append("colorWay", JSON.stringify(colorWay));
    }

    const response = await axiosInstance.post(Product_API, formData);

    if (response.data) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Product Name`}
        value={name}
        placeholder={`Enter Product Name`}
        setState={setName}
      />
      <ImageInput
        label={`Card Image`}
        value={cardImage}
        setState={setCardImage}
        placeholder={`Enter Product Card  image`}
        allowCreateImage={true}
      >
        Attach Preview Image
      </ImageInput>

      <div
        style={{
          marginTop: "10px",
        }}
      >
        <p className="input_field_label">Product Colorway</p>
        <AsyncSelect
          styles={{
            control: (styles) => ({
              ...styles,
              backgroundColor: "#292929",
              outline: "none",
              color: "white",
              width: "100%",
              border: "1px solid #ccc",
              borderRadius: "5px",
              marginBottom: "10px",
              marginTop: "10px",
            }),
          }}
          isMulti
          cacheOptions
          closeMenuOnSelect={false}
          defaultOptions={colorways}
          placeholder="Add recommended color"
          options={colorways}
          onChange={(e) => setColorWay(e.map((id) => id.value))}
        />
      </div>
      <ShortTextInput
        label={`Product SKU`}
        value={sku}
        placeholder={`Enter Product SKU`}
        setState={setSku}
      />
      <ShortTextInput
        label={`Product Description`}
        value={description}
        placeholder={`Enter Product Description`}
        setState={setDescription}
      />

      <MultiImageInput
        fieldId="1"
        state={images}
        setState={setImages}
        allowCreateImage
        previewImages={previewImages}
        setPreviewImages={setPreviewImages}
      >
        Attach Main Images
      </MultiImageInput>

      <FormSubmitButton text="Create Product" />
    </Form>
  );
}

export default CreateProduct;
